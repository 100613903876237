<template>
  <div>
    <v-app-bar
      class="mb-xl-navbar"
      color="#FFFFFF"
      elevation="0"
      app
      style="border-bottom: 1px solid #d4dbff !important; display: contents"
    >
      <div class="navidad-navbar"></div>
      <div class="d-flex pt-1" style="background: white">
        <v-avatar
          class="mr-6 cursor border-profile-img d-flex justify-center align-center"
        >
          <img :src="img" alt="Foto de perfil" />
        </v-avatar>
        <v-card
          elevation="0"
          class="d-flex flex-column pt-2"
          style="width: 200px"
        >
          <template>
            <p class="mb-0 sidebar__user-name text-1-lines">
              <strong> {{ this.datauser.data.nombre }} </strong>
            </p>
            <p class="mb-0 sidebar__user-profile text-1-lines">
              {{ this.datauser.data.cargo }}
            </p>
          </template>
        </v-card>
      </div>
    </v-app-bar>
    <v-row>
      <!-- Contenedor del iframe que ocupa toda la pantalla -->
      <v-col cols="12" class="full-screen-iframe">
        <iframe
          :src="this.datauser.data.url"
          frameborder="0"
          class="iframe"
        ></iframe>
      </v-col>

      <!-- Pop-up 1 -->
      <v-dialog v-model="modal.popup1" justify="center" max-width="470">
        <v-card class="px-3">
          <v-card-title
            style="justify-content: center"
            class="text-h5 request-project"
          >
            ¡Bienvenido a BRM!
          </v-card-title>
          <v-card-text class="request-txt-project mb-0 pb-0">
            <img
              alt="Imagen BRM"
              width="150"
              class="mt-1"
              src="../../assets/img/brm.png"
            />
            <p class="mt-3" style="font-size: 17px">
              ¡Bienvenido a nuestro equipo! Estamos emocionados de tenerte con
              nosotros. Juntos, construiremos un futuro exitoso. ¡Comencemos
              este increíble viaje laboral!
            </p>
          </v-card-text>
          <v-card-actions class="mx-5 pt-0">
            <v-btn
              block
              rounded
              small
              class="mb-4"
              color="#466be3"
              outlined
              @click="modal.popup1 = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Api from "@/utils/api";
export default {
  data() {
    return {
      img: "https://duvapi.tars.dev/storage/images/IW5XYkbqmQWGLXE81N4kqjfHnfHoWeKxST5A8tfU.png",
      datauser: null,
      modal: {
        popup1: true,
      },
      i: 0,
    };

  },
  mounted() {
    const userData = this.$route.params.userData;
    if (userData === undefined) {
      this.$router.push({ name: "Newuser" });
    } else {
      this.datauser = userData;
      this.intervalId = setInterval(this.closeUser, 10000);
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    closeUser() {
      var data = new FormData();
      data.append("email", this.datauser.email);
      data.append("cedula", this.datauser.cedula);
      data.append("sesion", this.datauser.data.sesion);
      data.append("req", i);
      Api.newUser()
        .logOutNewUser(data)
        .then((data) => {
          i++;
        });
    },
  },
};
</script>

<style scoped>
.full-screen-iframe {
  width: 100%;
  height: 900px;
  background-color: #fff;
  background-color: #fff; /* Color de fondo opcional */
}

.iframe {
  width: 100%;
  height: 100%;
}

@import "./../../assets/css/main.less";

.navidad-navbar {
  background-repeat: round;
  background-size: contain;
  height: 100%;
  width: -webkit-fill-available;
}
.in-active-no {
  background-color: aliceblue !important;
}

.active-no {
  background-color: #fff !important;
}

.cursor {
  cursor: pointer;
}

.border-profile-img {
  border: 2px solid;
  border-radius: 104px;
  border-color: #3b5bc0;
}

.mb-sm-navbar {
  display: none;
}

.card-title {
  font-family: "RobotoMedium";
}

.notifications-card {
  border-radius: 10px;
}

.notifications-name {
  width: 85%;
  font-family: "RobotoRegular";
}

.circle {
  width: 13px;
  height: 13px;
  background-color: #1867c0;
  color: #1867c0;
  border: 1px solid #fff;
}

.notifications-card:hover {
  background-color: #466be3;
  color: #fff;
}

.profile-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px !important;
}

.nav-bar__list-tutorial {
  background: transparent;
  box-shadow: none !important;
  padding: 0;
}

.navbarUser__dialog-apps {
  width: 100% !important;
}

.sidebar__user-name {
  width: 200px;
  font-family: "RobotoMedium";
  color: #353535;
  overflow: hidden;
  text-align: left;
  line-height: 1rem !important;
}
.color-tooltip {
  color: #fff;
  font-size: 18px;
  height: 50px;
}
.sidebar__user-profile {
  width: 200px;
  font-family: "RobotoMedium";
  font-style: italic;
  color: #626262;
  overflow: hidden;
  text-align: left;
}

.navbar-button {
  color: #fff;
  background-color: #466be3 !important;
}

.navbar-button:hover {
  background-color: #324ca1 !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-xl-navbar {
    display: none;
  }
  .mb-sm-navbar {
    display: block;
    text-align: initial;
  }
  .sm-navbar {
    height: 90px !important;
    box-shadow: 2px 0px 2px rgba(62, 123, 250, 0.15),
      0px 4px 8px rgba(62, 128, 250, 0.3) !important;
    border-radius: 0px 0px 15px 15px !important;
  }
  .v-app-bar-title__content {
    width: 100% !important;
    overflow: initial;
    /* position: absolute; */
  }

  .txt-nav-title {
    font-family: "RobotoRegular";
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #466be3;
    margin-bottom: 0px;
  }

  .txt-nav {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-navbar {
    display: none;
  }
  .mb-sm-navbar {
    display: block;
    text-align: initial;
  }
  .sm-navbar {
    height: 90px !important;
    box-shadow: 2px 0px 2px rgba(62, 123, 250, 0.15),
      0px 4px 8px rgba(62, 128, 250, 0.3) !important;
    border-radius: 0px 0px 15px 15px !important;
  }
  .v-app-bar-title__content {
    width: 100% !important;
    overflow: initial;
    /* position: absolute; */
  }

  .txt-nav-title {
    font-family: "RobotoRegular";
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #466be3;
    margin-bottom: 0px;
  }

  .txt-nav {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-navbar {
    display: none;
  }
  .mb-sm-navbar {
    display: block;
    text-align: initial;
  }
  .sm-navbar {
    height: 90px !important;
    box-shadow: 2px 0px 2px rgba(62, 123, 250, 0.15),
      0px 4px 8px rgba(62, 128, 250, 0.3) !important;
    border-radius: 0px 0px 15px 15px !important;
  }
  .v-app-bar-title__content {
    width: 100% !important;
    overflow: initial;
    /* position: absolute; */
  }

  .txt-nav-title {
    font-family: "RobotoRegular";
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #466be3;
    margin-bottom: 0px;
  }

  .txt-nav {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-navbar {
    display: none;
  }
  .mb-sm-navbar {
    display: block;
    text-align: initial;
  }
  .sm-navbar {
    height: 88px !important;
    box-shadow: 2px 0px 2px rgba(62, 123, 250, 0.15),
      0px 4px 8px rgba(62, 128, 250, 0.3) !important;
    border-radius: 0px 0px 15px 15px !important;
  }
  .v-app-bar-title__content {
    width: 100% !important;
    overflow: initial;
    /* position: absolute; */
  }

  .txt-nav-title {
    font-family: "RobotoRegular";
    font-weight: 700;
    font-size: 26px;
    line-height: 34px;
    color: #466be3;
    margin-bottom: 0px;
  }

  .txt-nav {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0px;
  }
}

.button-help {
  /* background: #466be3; */
  cursor: pointer;
  border: none;
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  border-radius: 100px;
}

.button-help::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -4;
  width: 100%;
  height: 90%;
  background: linear-gradient(
    60deg,
    #2adb62,
    #e000b9,
    #c8de3c,
    #dea03c,
    #e82e20,
    #dea03c,
    #e000b9
  );
  background-size: 900%;
  border-radius: 10px;
  filter: blur(8px);
  animation: glowing 20s linear infinite;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
</style>
